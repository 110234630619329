import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style5">
        <div className="inner">
            <header className="major">
                <h1>Urban Structure Visualization Promotion Organization with  OGC</h1>
            </header>
            <div className="content">
                <p>usvpro is a member of ogc and operates in partnership with ogc.</p>
            </div>
        </div>
    </section>
)

export default BannerLanding
