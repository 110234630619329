import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerOgc from '../components/BannerOgc'
import { StaticImage } from "gatsby-plugin-image"

const Ogc = (props) => (
    <Layout>
        <Helmet>
            <title>i-urban revitalization of Japan with OGC</title>
            <meta name="description" content="An organization that promotes i-urban revitalization, an initiative of the Japanese government, together with OGC. Please feel free to contact us." />
        </Helmet>

        <BannerOgc />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>About OGC</h2>
                    </header>
                    <p>The Open Geospatial Consortium (OGC) is an international consortium of more than 500 businesses, government agencies, research organizations, and universities driven to make geospatial (location) information and services FAIR - Findable, Accessible, Interoperable, and Reusable.
                    <br/>
                    OGC's member-driven consensus process creates royalty free, publicly available, open geospatial standards. Existing at the cutting edge, OGC actively analyzes and anticipates emerging tech trends, and runs an agile, collaborative Research and Development (R&D) lab - the OGC Innovation Program - that builds and tests innovative prototype solutions to members' use cases.
                    </p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="image">
                        <StaticImage src="../images/intmeeting.jpg" alt="image" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>International Standards Committee</h3>
                            </header>
                            <p>The International Committee regularly holds study meetings with guests from overseas.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="image">
                        <StaticImage src="../images/ovsmeeting.jpg" alt="imgae" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Promotion of international exchange</h3>
                            </header>
                            <p>We also actively attend international conferences that are held regularly to promote technological development based on the latest information.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="image">
                        <StaticImage src="../images/intmembers.jpg" alt="imgae" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Members of the International Commission</h3>
                            </header>
                            <p>Nobuhiro Ishimaru, Chikako Kurokawa, Tomohisa Oishi, Kentaro Akahoshi and others</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Ogc